import React from 'react';
import { Layout, Button } from 'antd';
import './ImmediaExperience.scss';
import GrowthLogo from '@/assets/images/logo_new.svg';
import imgContent from '@/assets/images/Layer101_@2x.png';
const { Header, Content, Footer } = Layout;

function ImmediaExperience(props) {
  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };
  return (
    <>
      <Layout className="layout">
        <Header className="header">
          <div className="headerContent">
            <img src={GrowthLogo} alt="" className="logoImg" />
            <span className="textContent">增长魔方GrowthCube</span>
            <Button
              type="primary"
              className="headerButton"
              onClick={() => handleJumpToForm('targetForm')}
            >
              立即体验
            </Button>
          </div>
        </Header>
        <Content className="mainContent">
          <div className="contentBlock">
            <div className="textContent">
              <div className="mainTitle">让私域流量，激发生意新可能</div>
              <div className="mainText">玩转互联网，一个平台就够</div>
              <div className="mainText">
                一站式私域流量营销平台，让每一位商家都能高效享受互联网发展红利
              </div>
              <div className="mainBtn">
                <Button
                  type="primary"
                  className="contentButton"
                  onClick={() => handleJumpToForm('targetForm')}
                >
                  立即体验
                </Button>
              </div>
            </div>
            <div className="imgContent">
              <img alt="" src={imgContent} />
            </div>
          </div>
        </Content>
        <Footer className="footerContent">
          <div className="footerBlock">
            <div className="noticeType">平台通知</div>

            <div className="noticeContent">
              {/* <div className="noticeTitle">
                <span className="newButton">new</span>
                <span className="title">关于增长魔方融资成功的通知</span>
                <span className="date">2020-11-23</span>
              </div> */}
              <div className="noticeDetail">关于增长魔方融资成功的通知</div>
            </div>
          </div>
        </Footer>
      </Layout>
    </>
  );
}

export default ImmediaExperience;
