import React from 'react';
import { Avatar, Popover, Button, Typography } from 'antd';
import './UserExample.scss';
import GrowthLogo from '@/assets/images/logo@1x.png';
import ShopImg from '@/assets/images/shop.png';
import VideoImg from '@/assets/images/微信视频号.png';
import PublicImg from '@/assets/images/微信公众号.png';
import WechatBusinessImg from '@/assets/images/企业微信.png';
import WechatContactImg from '@/assets/images/微信小商店.png';

const { Text, Link } = Typography;

const UserExample = (props) => {
  const { openModal } = props;
  const handleJumpToForm = (anchorName) => {
    // if (anchorName) {
    //   let anchorElement = document.getElementById(anchorName);
    //   if (anchorElement) {
    //     anchorElement.scrollIntoView();
    //   }
    // }
    openModal();
  };
  const ImgContent = (
    <img src={WechatBusinessImg} alt="" style={{ width: 122, height: 122 }} />
  );
  return (
    <div className="totalPage">
      <div className="exampleLayout">
        <div className="exampleText">
          <span className="exampleTitle">客户案例</span>
          <span className="exampleDetail">
            了解真实案例，借鉴成功经验，助力推广
          </span>
        </div>
        <div className="exampleContainer">
          <span className="exampleContent">
            “自增长魔方成立以来我们就保持高度的关注和信赖，在产品发布初期，我们率先进行了试用，尤其在自动化营销和用户旅程管理方面提供了专业和高效的产品服务”
          </span>
          <span className="exampleName">
            <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
            <span className="name">高XX</span>
            <span className="business">XXX科技有限公司</span>
          </span>
        </div>
      </div>
      <div className="footerPage">
        <div className="footerFirst">
          <div className="footerContainer">
            <div className="footerblock">
              <img src={GrowthLogo} alt="" className="logoImg" />
              <span className="textContent">增长魔方GrowrhCube</span>
            </div>
            <div className="footerAdvice">版权所有:北京罗森科技有限公司</div>
            <div className="footerAdvice">商务合作:service_cube@163.com</div>
            <div className="footerAdvice">意见反馈:service_cube@163.com</div>
          </div>
          <div className="services">
            <div className="servicesAdviceTitle">服务条款</div>
            <div className="servicesAdvice">隐私政策</div>
            <div className="servicesAdvice">用户协议</div>
            <div className="servicesAdvice">关于我们</div>
            <div className="servicesAdvice">公司简介</div>
          </div>
          <div className="attention">
            <div className="attentionTitle">企业微信</div>
            <div className="attentionImg">
              <img src={WechatBusinessImg} alt="" />
            </div>
          </div>
          <div className="attention">
            <div className="attentionTitle">关注公众号</div>
            <div className="attentionImg">
              <img src={PublicImg} alt="" />
            </div>
          </div>
          <div className="attention">
            <div className="attentionTitle">关注视频号</div>
            <div className="attentionImg">
              <img src={VideoImg} alt="" />
            </div>
          </div>
          <div className="attention" style={{ marginRight: 0 }}>
            <div className="attentionTitle">关注小商店</div>
            <div className="attentionImg">
              <img src={ShopImg} alt="" />
            </div>
          </div>
        </div>
        <div className="copyrightContent">
          Copyright © 2020 - 2021 罗森科技. 　罗森科技 版权所有. 　
          <Link
            href="https://beian.miit.gov.cn/"
            target="_blank"
            style={{ color: '#999999' }}
          >
            网站备案: 京ICP备2020041420号-2
          </Link>
        </div>
      </div>

      {/* <div className="registerForm" id="targetForm">
        <span className="contactTitle">联系我们</span>
        <span className="contactContent">
          留下你的联系方式，团队将尽快与你取得联系
        </span>
        <div className="contactForm">
          <div className="FormImg">
            <img src={MaskGroupImg} alt=""></img>
          </div>
          <div dangerouslySetInnerHTML={{ __html: FormHtmlContent }}></div>
        </div>
      </div> */}
      <div className="hoverContent">
        <Popover placement="leftTop" content={123456789012} trigger="hover">
          <div className="hoverItem">
            <div className="phoneItemImg"></div>
            <div>电话咨询</div>
          </div>
        </Popover>
        <Popover placement="left" content={ImgContent} trigger="hover">
          <div className="hoverItem">
            <div className="wechatItemImg"></div>
            <div>微信咨询</div>
          </div>
        </Popover>
        <div
          className="hoverItem"
          onClick={handleJumpToForm}
          style={{ borderBottom: 'none' }}
        >
          <div className="addItemImg"></div>
          <div>立即体验</div>
        </div>
      </div>
    </div>
  );
};

export default UserExample;
